<template>
  <!-- 新增布局按钮 -->
  <el-button type="primary" size="small" @click="linkToAddCustomFloor"> 新增布局 </el-button>
</template>

<script>
export default {
  methods: {
    linkToAddCustomFloor() {
      this.$router.push({
        path: '/user/addCustomFloor'
      })
    }
  }
}
</script>

<style>
</style>